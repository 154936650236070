/** @jsx jsx */
import { jsx } from "theme-ui"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaTelegram,
  FaYoutube,
  FaSpotify,
} from "react-icons/fa"

const size = 35

export default ({ ...props }) => (
  <section>
    <div sx={{ display: `flex`, justifyContent: `center` }} {...props}>
      <Link
        to="https://www.facebook.com/arturolorenzoni"
        label="Segui Arturo Lorenzoni su Facebook"
      >
        <FaFacebookF
          size={size}
          sx={{ variant: `button.social` }}
          onClick={() => {
            trackCustomEvent({
              category: "FollowFB",
              action: "Click",
            })
          }}
        />
      </Link>
      <Link
        to="https://www.twitter.com/arturolorenzoni"
        label="Segui Arturo Lorenzoni su Twitter"
      >
        <FaTwitter
          size={size}
          sx={{ variant: `button.social` }}
          onClick={() => {
            trackCustomEvent({
              category: "FollowTW",
              action: "Click",
            })
          }}
        />
      </Link>
      <Link
        to="https://www.instagram.com/arturolorenzoni"
        label="Segui Arturo Lorenzoni su Instagram"
      >
        <FaInstagram
          size={size}
          sx={{ variant: `button.social` }}
          onClick={() => {
            trackCustomEvent({
              category: "FollowIG",
              action: "Click",
            })
          }}
        />
      </Link>
      <Link
        to="https://www.youtube.com/channel/UCE7pBaD0CPP23JuxfPjxx2w"
        label="Segui Arturo Lorenzoni su YouTube"
      >
        <FaYoutube
          size={size}
          sx={{ variant: `button.social` }}
          onClick={() => {
            trackCustomEvent({
              category: "FollowYT",
              action: "Click",
            })
          }}
        />
      </Link>
      <Link
        to="https://t.me/arturolorenzoni"
        label="Segui Arturo Lorenzoni su Telegram"
      >
        <FaTelegram
          size={size}
          sx={{ variant: `button.social` }}
          onClick={() => {
            trackCustomEvent({
              category: "FollowT",
              action: "Click",
            })
          }}
        />
      </Link>
      <Link
        to="https://open.spotify.com/user/j08wmp45iyhkin44luqir6mpq"
        label="Segui Arturo Lorenzoni su Spotify"
      >
        <FaSpotify
          size={size}
          sx={{ variant: `button.social` }}
          onClick={() => {
            trackCustomEvent({
              category: "FollowSpotify",
              action: "Click",
            })
          }}
        />
      </Link>
    </div>
  </section>
)

const Link = ({ to, children, label }) => (
  <a href={to} aria-label={label} target="_blank" rel="noreferrer">
    {children}
  </a>
)

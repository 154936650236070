/** @jsx jsx **/
import { jsx } from "theme-ui"
import { Link } from "gatsby"

const BottomBanner = () => (
  <div
    sx={{
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
      textTransform: `uppercase`,
      textAlign: `center`,
      // mb: 1,
      fontSize: [2, 4],
      letterSpacing: 2,
      fontWeight: 600,
    }}
  >
    <div sx={{ bg: `red`, width: `100%`, py: 2 }}>
      <Link
        to="/volontari/"
        sx={{
          color: `white`,
          textDecoration: `none`,
          mr: [null, 2],
        }}
      >
        Volontari
      </Link>
    </div>
    <div sx={{ bg: `secondary`, width: `100%`, py: 2 }}>
      <Link
        to="/dona/"
        sx={{
          color: `white`,
          textDecoration: `none`,
          mr: [null, 2],
        }}
      >
        Dona
      </Link>
    </div>
    <div sx={{ bg: `blue`, width: `100%`, py: 2 }}>
      <Link
        to="/eventi/"
        sx={{
          color: `white`,
          textDecoration: `none`,
          mr: [null, 2],
        }}
      >
        Eventi
      </Link>
    </div>
  </div>
)

export default BottomBanner

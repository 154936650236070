/** @jsx jsx */
import { jsx, Button } from "theme-ui"
import { useEffect } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { FiX } from "react-icons/fi"
import Share from "./share-buttons"

const Modal = ({ isShown, setIsShown }) => {
  // const [isShown, setIsShown] = useState(false)

  const closeModal = () => {
    setIsShown(false)
    trackCustomEvent({
      category: "CloseModalVideoEnd",
      action: "Click",
    })
    document.body.style.overflow = ``
  }

  useEffect(() => {
    if (!sessionStorage.modalVideo) {
      const timer = setTimeout(() => {
        setIsShown(true)
        document.body.style.overflow = `hidden`
        sessionStorage.modalVideo = 1
      }, 1000)

      return () => clearTimeout(timer)
    }
  }, [setIsShown])

  return isShown ? (
    <div
      sx={{
        position: `fixed`,
        display: `flex`,
        flexDirection: `column`,
        top: 0,
        left: 0,
        height: `100vh`,
        width: `100%`,
        zIndex: 10003,
        bg: `primary`,
        borderStyle: `solid`,
        borderWidth: `30px`,
        borderColor: `primary`,
        textAlign: `center`,
      }}
    >
      <Button
        sx={{
          variant: `button.default`,
          p: 0,
          mx: `auto`,
          mb: 3,
        }}
        onClick={closeModal}
        type="button"
        data-dismiss="modal"
        aria-label="Close"
      >
        <FiX size={30} /> Torna al sito
      </Button>
      <h2
        sx={{
          mt: 3,
          mx: `auto`,
          color: `red`,
        }}
      >
        Arturo Lorenzoni per il Veneto
      </h2>
      <iframe
        sx={{
          m: `auto`,
          width: `100%`,
          height: [`60%`, `100%`],
          borderRadius: `15px`,
        }}
        title="aboutVideo"
        src={`https://www.youtube-nocookie.com/embed/ACcejYAdkw4?hl=it&cc_lang_pref=it&cc_load_policy=1&autoplay=1&mute=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <Share
        title={`Arturo Lorenzoni per il Veneto`}
        url={`https://www.youtube.com/watch?v=ACcejYAdkw4`}
        divider={false}
        color={`white`}
        colorD={`blue`}
      />
    </div>
  ) : null
}

export default Modal

/** @jsx jsx **/
import { jsx, Box } from "theme-ui"
import { Link } from "gatsby"
import Img from "gatsby-image"

import theme from "../gatsby-plugin-theme-ui/index"
import Container from "./container"
import BottomBanner from "./header/bottom"

const PageTitle = ({ avatar, title, subtitle }) => (
  <Box
    sx={{
      bg: `background`,
      textAlign: "center",
    }}
  >
    {avatar && <Img fluid={avatar} sx={{ margin: `auto`, width: `150px` }} />}
    <h1
      sx={{
        margin: `auto`,
        maxWidth: 900,
        fontSize: [40, 50],
        color: `text`,
        textTransform: `uppercase`,
        my: 3,
      }}
    >
      {title}
    </h1>
    {subtitle && (
      <h3
        sx={{
          maxWidth: 640,
          margin: `auto`,
          color: `text`,
          mb: 3,
        }}
      >
        {subtitle}
      </h3>
    )}
  </Box>
)

export default ({ title, subtitle, avatar }) => (
  <header>
    <Container
      sx={{
        // marginTop: 4,
        padding: 3,
        display: `flex`,
        flexDirection: `row`,
        alignItems: `center`,
        justifyContent: `space-between`,
      }}
    >
      <div
        sx={{
          mt: [2, null],
          width: [`100%`, `fit-content`],
          textAlign: [`center`, null],
        }}
      >
        <Link to="/">
          <img
            src="/images/lorenzoni_brand.jpg"
            alt="Arturo Lorenzoni Presidente Veneto 2020"
            width="150px"
          />
        </Link>
      </div>
      <div
        sx={{
          display: [`none`, `grid`],
          gridTemplateColumns: `repeat(4, auto)`,
          gridGap: `2`,
        }}
      >
        <Link
          activeStyle={{ color: theme.colors.primary }}
          sx={{ variant: `button.link` }}
          to="/chi-sono/"
        >
          CHI SONO
        </Link>
        <Link
          activeStyle={{ color: theme.colors.primary }}
          sx={{ variant: `button.link` }}
          to="/news/"
        >
          NEWS
        </Link>
        <Link
          activeStyle={{ color: theme.colors.primary }}
          sx={{ variant: `button.link` }}
          to="/voto/"
        >
          COME SI VOTA
        </Link>
        <Link
          activeStyle={{ color: theme.colors.primary }}
          sx={{ variant: `button.link` }}
          to="/contatti/"
        >
          CONTATTI
        </Link>

        {/* <Link sx={{ variant: `button.action` }} to="/partecipa/">
          PARTECIPA
        </Link> */}
      </div>
    </Container>
    <BottomBanner />
    {title && <PageTitle title={title} subtitle={subtitle} avatar={avatar} />}
  </header>
)

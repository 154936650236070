/** @jsx jsx **/
import { jsx, Button } from "theme-ui"

const PrivacyBanner = ({ setToggle, ...props }) => (
  <div
    sx={{
      mt: 3,
      bg: `red`,
      p: 4,
      borderRadius: `5px`,
    }}
    {...props}
  >
    <h3>Trattamento dei dati personali</h3>
    <p sx={{ fontSize: 2 }}>
      Dichiaro di aver letto e accettato la{" "}
      <a href="/privacy/" target="_blank" sx={{ color: `white` }}>
        Privacy Policy
      </a>{" "}
      pubblicata sul sito ArturoLorenzoni.it. Sono consapevole che i dati da me
      rilasciati possono avere natura particolare ai sensi dell'art. 9, Reg. UE
      2016/679. Pertanto, esprimo il mio consenso al trattamento degli stessi
      nei termini, per le finalità e alle condizioni di cui all’informativa
      contenuta nella richiamata privacy policy, nonché ai sensi della normativa
      in materia di protezione dei dati personali di cui al D.Lgs. 196/2003 e di
      cui al Reg. (UE) 2016/679
    </p>
    <Button
      sx={{ variant: `button.default`, mx: `auto` }}
      type="button"
      onClick={() => setToggle(false)}
    >
      Chiudi
    </Button>
  </div>
)

export default PrivacyBanner

/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"

import PropTypes from "prop-types"

import Header from "./header"
import MobileNav from "./mobile-nav"
import Footer from "./footer"
import Title from "./page-title"
import Cookie from "./cookie-consent"
import Newsletter from "./newsletter"
import Liste from "./liste"
import Modal from "./modal"

const Layout = ({
  title = true,
  newsletter = false,
  subtitle,
  avatar,
  liste = true,
  children,
}) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <div
      sx={{
        height: `100%`,
        minHeight: `100vh`,
        borderTopStyle: `solid`,
        borderTopWidth: `10px`,
        borderTopColor: `primary`,
        bg: `white`,
      }}
    >
      <Modal isShown={showModal} setIsShown={setShowModal} />
      <Header />
      <main
        sx={{
          position: `relative`,
        }}
      >
        {/* <TopBanner /> */}
        {title && <Title title={title} subtitle={subtitle} avatar={avatar} />}
        {/* <Container sx={{ p: 3, maxWidth: 600 }}>{children}</Container> */}
        {children}
        <Cookie />
        {!newsletter ? null : <Newsletter />}
        {liste && <Liste />}
        <Footer />
        <MobileNav />
      </main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

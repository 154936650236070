/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      lista01: file(relativePath: { eq: "liste/vcv.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lista02: file(relativePath: { eq: "liste/pd.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lista03: file(relativePath: { eq: "liste/+veneto.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lista04: file(relativePath: { eq: "liste/verdi.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lista05: file(relativePath: { eq: "liste/sanca.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const Logo = ({ name, img }) => {
    return (
      <div
        sx={{
          textAlign: `center`,
          mx: 2,
          width: `100px`,
          bg: `white`,
        }}
      >
        <Img fluid={img} alt={name} />
      </div>
    )
  }
  return (
    <section sx={{ textAlign: `center`, bg: `primary`, py: 2, mt: 4 }}>
      <div
        sx={{
          mx: [2, `auto`],
          my: 4,
          bg: `white`,
          maxWidth: 690,
          py: 3,
          boxShadow: `-10px 10px 80px rgba(0,0,0,.75)`,
          borderRadius: `15px`,
        }}
      >
        <div
          sx={{
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
          }}
        >
          <Logo
            name="Il Veneto che vogliamo"
            img={data.lista01.childImageSharp.fluid}
          />
          <Logo
            name="Partito Democratico"
            img={data.lista02.childImageSharp.fluid}
          />
          <Logo
            name="+Veneto In Europa Volt"
            img={data.lista03.childImageSharp.fluid}
          />
          <Logo name="Europa Verde" img={data.lista04.childImageSharp.fluid} />
          <Logo name="Sanca Veneta" img={data.lista05.childImageSharp.fluid} />
        </div>
        <Link
          sx={{
            variant: `button.default`,
            m: `auto`,
            bg: `primary`,
            color: `white`,
            mt: 4,
          }}
          to="/coalizione/"
        >
          La Coalizione
        </Link>
      </div>
      <p sx={{ color: `white` }}>
        Leggi la{" "}
        <Link
          sx={{
            fontStyle: `italic`,
            textTransform: `uppercase`,
            fontWeight: 900,
            color: `white`,
          }}
          to="/news/lettera-ai-veneti/"
        >
          Lettera ai Veneti
        </Link>{" "}
        di Arturo
      </p>
    </section>
  )
}

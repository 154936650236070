/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import Container from "./container"
import Social from "./social"
import Avatar from "./avatar"

const Dot = () => (
  <span sx={{ fontSize: 5, fontWeight: 900, color: `primary` }}>&middot;</span>
)

export default () => (
  <footer>
    <Container
      sx={{
        textAlign: `center`,
        my: 3,
        p: 3,
      }}
    >
      <div
        sx={{
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `center`,
          alignItems: `center`,
        }}
      >
        <Avatar />
        <div sx={{ textAlign: `center`, textTransform: `uppercase`, mt: 2 }}>
          <h1
            sx={{
              textDecoration: `none`,
              color: `#000`,
              fontSize: [5, 6],
              mb: 2,
            }}
          >
            Arturo <span sx={{ color: `secondary` }}>Lorenzoni</span>
          </h1>
          <h3
            sx={{
              color: `textMuted`,
              fontSize: [3, 4],
              fontWeight: 500,
              letterSpacing: 3,
            }}
          >
            Candidato Presidente alla Regione Veneto
          </h3>
        </div>
      </div>
      <Social />
    </Container>
    <Container
      sx={{
        display: `flex`,
        flexDirection: `row`,
        alignItems: `center`,
        justifyContent: `center`,
        mb: [`6`, `4`],
      }}
    >
      <div
        sx={{
          display: `flex`,
          flexWrap: `wrap`,
          justifyContent: `space-evenly`,
          alignItems: `center`,
        }}
      >
        <Link sx={{ variant: `button.link.faint` }} to="/privacy/">
          Privacy Policy
        </Link>
        <Dot />
        <Link sx={{ variant: `button.link.faint` }} to="/cookies/">
          Cookie Policy
        </Link>
      </div>
    </Container>
  </footer>
)

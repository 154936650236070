/** @jsx jsx **/
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import { FiHome, FiUser, FiMail, FiAlignCenter, FiEdit2 } from "react-icons/fi"

import theme from "../gatsby-plugin-theme-ui/index"

export default () => (
  <div
    sx={{
      bg: `white`,
      p: `2`,
      position: `fixed`,
      bottom: 0,
      right: 0,
      left: 0,
      zIndex: 10001,
      display: [`flex`, `none`],
      justifyContent: `space-around`,
      borderTop: `1px solid #000`,
    }}
  >
    <MobileLink to="/">
      <FiHome sx={{ mb: `1` }} size={30} />
      Home
    </MobileLink>
    <MobileLink to="/chi-sono/">
      <FiUser sx={{ mb: `1` }} size={30} />
      Chi Sono
    </MobileLink>
    <MobileLink to="/voto/">
      <FiEdit2 sx={{ mb: `1` }} size={30} />
      Come si vota
    </MobileLink>
    <MobileLink to="/news/">
      <FiAlignCenter sx={{ mb: `1` }} size={30} />
      News
    </MobileLink>
    <MobileLink to="/contatti/">
      <FiMail sx={{ mb: `1` }} size={30} />
      Contatti
    </MobileLink>
  </div>
)

const MobileLink = ({ children, to }) => (
  <Link
    sx={{
      color: `text`,
      fontSize: `1`,
      display: `flex`,
      flexDirection: `column`,
      alignItems: `center`,
      textDecoration: `none`,
    }}
    to={to}
    activeStyle={{ color: theme.colors.secondary }}
  >
    {children}
  </Link>
)

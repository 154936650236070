/** @jsx jsx */
import { jsx, Divider, Box } from "theme-ui"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { FaFacebookF, FaTwitter, FaWhatsapp } from "react-icons/fa"

const size = 30

export default ({
  title,
  url,
  divider = true,
  color = `black`,
  colorD = `primary`,
  ...props
}) => {
  return (
    <div
      sx={{
        display: `flex`,
        alignItems: `center`,
        justifyContent: `flex-end`,
        mt: `50px`,
      }}
      {...props}
    >
      <Divider
        sx={{
          color: colorD ? colorD : `primary`,
          mr: [2, 4],
          borderWidth: `5px`,
          flexGrow: `1`,
        }}
      />
      <Box
        sx={{
          textTransform: `uppercase`,
          fontFamily: `body`,
          fontSize: 3,
          letterSpacing: 3,
          fontWeight: 500,
          color: color,
          mr: 3,
        }}
      >
        Condividi
      </Box>

      <a
        href={`https://facebook.com/sharer/sharer.php?u=${url}?utm_medium=facebook`}
        target="_blank"
        rel="noreferrer"
      >
        <FaFacebookF
          sx={{ variant: `button.social`, color: color ? color : `` }}
          aria-label={"Condividi su Facebook"}
          size={size}
          onClick={() => {
            trackCustomEvent({
              category: "ShareFB",
              action: "Click",
            })
          }}
        />
      </a>
      <a
        href={`https://twitter.com/intent/tweet/?text=${title}&url=${url}?utm_medium=twitter`}
        target="_blank"
        rel="noreferrer"
      >
        <FaTwitter
          sx={{ variant: `button.social`, color: color ? color : `` }}
          aria-label={"Condividi su Twitter"}
          size={size}
          onClick={() => {
            trackCustomEvent({
              category: "ShareTW",
              action: "Click",
            })
          }}
        />
      </a>
      <a
        href={`https://wa.me/?text=${url}?utm_medium=whatsapp`}
        target="_blank"
        rel="noreferrer"
      >
        <FaWhatsapp
          sx={{ variant: `button.social`, color: color ? color : `` }}
          aria-label={"Condividi su WhatsApp"}
          size={size}
          onClick={() => {
            trackCustomEvent({
              category: "ShareWA",
              action: "Click",
            })
          }}
        />
      </a>
    </div>
  )
}

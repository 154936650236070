/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Avatar = () => {
  const data = useStaticQuery(graphql`
    query {
      avatar: file(relativePath: { eq: "arturo-avatar.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div
      sx={{
        borderRadius: `50%`,
        width: [`150px`],
        mr: 3,
        bg: `primary`,
        border: `2px solid red`,
        borderStyle: `solid`,
        borderWidth: `4px`,
        borderColor: `secondary`,
        "&:hover": {
          borderColor: `primary`,
          bg: `secondary`,
        },
      }}
    >
      <Img
        fluid={data.avatar.childImageSharp.fluid}
        sx={{
          borderRadius: `50%`,
          borderStyle: `solid`,
          borderWidth: `3px`,
          borderColor: `white`,
        }}
      />
    </div>
  )
}

export default Avatar

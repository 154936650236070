/** @jsx jsx */
import { jsx } from "theme-ui"

const Form = ({ title, children }) => {
  return (
    <div
      sx={{
        maxWidth: 620,
        fontWeight: 600,
        margin: `auto`,
        marginTop: 4,
        display: `flex`,
        flexDirection: `column`,
        borderRadius: `5px`,
        bg: `secondary`,
        color: `white`,
        p: `2rem`,
        mb: `2.5rem`,
      }}
    >
      <h2
        sx={{
          letterSpacing: `0.075em`,
          textTransform: `uppercase`,
          color: `red`,
        }}
      >
        {title}
      </h2>
      {children}
    </div>
  )
}

export default Form

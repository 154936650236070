/** @jsx jsx */
import { useState, useEffect } from "react"
import { jsx, Button, Input, Label, Checkbox, Spinner, Select } from "theme-ui"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import isEmail from "validator/lib/isEmail"

import { FiSend } from "react-icons/fi"
import Form from "./form"
import Privacy from "./forms/privacy"

export default () => {
  const mailcheck = require("mailcheck")
  const [userSuggestion, setUserSuggestion] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [error, setError] = useState("")
  const [togglePrivacy, setTogglePrivacy] = useState(false)
  const [formResponse, setFormResponse] = useState("")
  const url = process.env.NETLIFY_FUNCTIONS_URL || ""

  useEffect(() => {
    mailcheck.run({
      email: userEmail,
      suggested: function (suggestion) {
        setUserSuggestion(suggestion.full)
      },
      empty: () => setUserSuggestion(""),
    })
  }, [mailcheck, userEmail])

  function addEmail(e) {
    e.preventDefault()
    setError("")
    setIsLoading(true)

    if (
      !userEmail ||
      !document.newsletter.nome.value ||
      !document.newsletter.cognome.value ||
      !document.newsletter.provincia.value
    ) {
      setIsLoading(false)
      setError("Tutti i campi sono obbligatori")
      return
    }

    if (!isEmail(userEmail)) {
      setIsLoading(false)
      setError("E-mail non valida")
      return
    }

    trackCustomEvent({
      category: "Newsletter",
      action: "Submit",
    })

    fetch(`${url}/.netlify/functions/subscribe`, {
      method: "POST",
      body: JSON.stringify({
        email: userEmail,
        nome: document.newsletter.nome.value,
        cognome: document.newsletter.cognome.value,
        provincia: document.newsletter.provincia.value,
      }),
    })
      .then(response => response.json())
      .then(data => {
        setIsLoading(false)
        setUserEmail("")
        setFormResponse(data.message)
      })
      .catch(error => {
        setIsLoading(false)
        setUserEmail("")
        setFormResponse("Errore, riprova più tardi.")
      })
  }

  return (
    <Form title="Rimani Aggiornato, Iscriviti alla Newsletter">
      {error && <p sx={{ color: `white`, pt: 2 }}>{error}</p>}
      {formResponse === "" && !isLoading && (
        <form name="newsletter" onSubmit={addEmail}>
          <Label htmlFor="nome">Nome</Label>
          <Input
            type="text"
            name="nome"
            id="nome"
            sx={{
              variant: `input.newsletter`,
            }}
            required
          />
          <Label htmlFor="cognome">Cognome</Label>
          <Input
            type="text"
            name="cognome"
            id="cognome"
            sx={{
              variant: `input.newsletter`,
            }}
            required
          />
          <Label htmlFor="email">Indirizzo E-Mail</Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={userEmail}
            onChange={e => setUserEmail(e.target.value)}
            sx={{
              variant: `input.newsletter`,
            }}
            required
          />

          {userSuggestion ? (
            <p
              sx={{
                color: `white`,
                fontSize: `12px`,
                textAlign: `center`,
                mb: 3,
              }}
            >
              Intendi dire{" "}
              <Button
                name="suggestion"
                type="button"
                onClick={() => {
                  setUserEmail(userSuggestion)
                }}
                sx={{
                  textDecoration: `underline`,
                  fontWeight: 600,
                  color: `white`,
                  bg: `secondary`,
                  px: `0`,
                  py: `0`,
                }}
              >
                {userSuggestion}
              </Button>
              ?
            </p>
          ) : null}
          <Label htmlFor="provincia">Provincia</Label>
          <Select
            name="provincia"
            id="provincia"
            sx={{
              variant: `input.newsletter`,
            }}
            required
          >
            <option value="">Seleziona</option>
            <option value="Belluno">Belluno</option>
            <option value="Padova">Padova</option>
            <option value="Rovigo">Rovigo</option>
            <option value="Treviso">Treviso</option>
            <option value="Venezia">Venezia</option>
            <option value="Verona">Verona</option>
            <option value="Vicenza">Vicenza</option>
            <option value="Altro">Altro</option>
          </Select>
          <div sx={{ display: `flex` }}>
            <Label htmlFor="privacy">
              <Checkbox
                name="privacy"
                id="privacy"
                sx={{
                  variant: `input.newsletter.checkbox`,
                }}
                onChange={() => setIsChecked(!isChecked)}
              />

              <div sx={{ ml: 3 }}>
                Dichiaro di aver letto e presto il consenso al trattamento dei
                dati personali sulla base della{" "}
                <a href="/privacy/" target="_blank" sx={{ color: `white` }}>
                  Privacy Policy
                </a>
              </div>
            </Label>
            <Button
              type="button"
              sx={{
                bg: `secondary`,
                fontStyle: `italic`,
                textDecoration: `underline`,
              }}
              onClick={() => setTogglePrivacy(!togglePrivacy)}
            >
              ?
            </Button>
          </div>
          {togglePrivacy && <Privacy setToggle={setTogglePrivacy} />}
          <Button
            name="submit"
            type="submit"
            sx={{
              variant: `input.newsletter.button`,
            }}
            disabled={!isChecked ? true : false}
          >
            Invia <FiSend sx={{ transform: `rotate(45deg)` }} />
          </Button>
        </form>
      )}
      {formResponse === "" && isLoading && (
        <Spinner sx={{ m: `auto`, color: `red` }} />
      )}
      {formResponse !== "" && !isLoading && (
        <p sx={{ color: `white`, pt: 2 }}>{formResponse}</p>
      )}
    </Form>
  )
}

/** @jsx jsx */
import { jsx, Box } from "theme-ui"

import Img from "gatsby-image"

export default ({ avatar, title, subtitle }) => (
  <Box
    sx={{
      bg: `background`,
      textAlign: "center",
    }}
  >
    {avatar && (
      <div
        sx={{
          borderRadius: `50%`,
          mt: 2,
          width: `150px`,
          mx: `auto`,
          bg: `primary`,
          border: `2px solid red`,
          borderStyle: `solid`,
          borderWidth: `5px`,
          borderColor: `secondary`,
          "&:hover": {
            borderColor: `primary`,
            bg: `secondary`,
          },
        }}
      >
        <Img
          fluid={avatar}
          sx={{
            borderRadius: `50%`,
            borderStyle: `solid`,
            borderWidth: `3px`,
            borderColor: `white`,
          }}
        />
      </div>
    )}
    <h1
      sx={{
        margin: `auto`,
        fontSize: [40, 50],
        color: `text`,
        textTransform: `uppercase`,
        my: 4,
      }}
    >
      {title}
    </h1>
    {subtitle && (
      <h3
        sx={{
          maxWidth: 640,
          margin: `auto`,
          color: `text`,
          mb: 3,
        }}
      >
        {subtitle}
      </h3>
    )}
  </Box>
)

import React from "react"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

export default () => (
  <CookieConsent
    location="none"
    buttonText="Accetto"
    cookieName="cookieconsent"
    style={{
      position: "fixed",
      bottom: "0px",
      left: "0px",
      right: "0px",
      borderTopLeftRadius: `20px`,
      borderTopRightRadius: `20px`,
      background: "#2B373B",
      justifyContent: "center",
      zIndex: 10002,
    }}
    buttonStyle={{
      marginTop: "0px",
      background: "white",
      color: "#2B373B",
      borderRadius: "5px",
      padding: "10px 20px",
      textTransform: "uppercase",
      fontWeight: 800,
    }}
    contentStyle={{ flex: "100%", textAlign: "center", marginBottom: "0px" }}
    expires={30}
  >
    <p style={{ fontSize: `14px` }}>
      Questo sito utilizza cookie per migliorare la tua esperienza di
      navigazione.
      <br />
      Se continui a navigare accetterai l'uso di questi cookie.
    </p>
    <p>
      <Link to="/cookies/" style={{ fontSize: `14px`, color: "white" }}>
        Consulta la cookies policy
      </Link>
    </p>
  </CookieConsent>
)
